<template>
  <v-dialog
    v-model="dialogBaixaOs"
    @click:outside="fecharBaixaOs"
    @keydown.esc="fecharBaixaOs"
    width="70vw"
    scrollable
  >
    <v-card tile>
      <!-- HEADE DIALOG -->
      <v-card-title class="px-6 toolbar">
        <span class="white--text">
          {{ $tc("global.ordensServicos") + ' -' }}
          {{
            ordem_servico.condicao_pagamento === 2
              ? $tc("global.aPrazo")
              : $tc("global.aVista")
          }}
        </span>    

        <v-spacer></v-spacer>

        <span class="white--text pr-4">
          {{ ordem_servico.data | dateFormat("dd/MM/yyyy") }}
        </span>

        <v-btn c icon dark @click="fecharBaixaOs">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- CORPO DIALOG -->
      <v-card-text class="pa-6">
        <!-- DADOS DO CLIENTE COM DEPOSITO VALOR E DESCONTO -->
        <v-card
          width="100%"
          outlined
          class="d-flex justify-space-between align-center pa-4"
        >
          <div>
            <span class="text-h6 font-weight-bold">
              {{ $tc("global.cliente") }}:
            </span>
            <span class="subtitle-1 ml-2">{{ ordem_servico.cliente_nome }}</span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.deposito") }}:
              </span>
              {{ ordem_servico.deposito_nome }}
            </span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.valor") }}:
              </span>
              <span v-if="ordem_servico.moeda_sigla === 'G$'">
                {{ ordem_servico.moeda_sigla }} {{ ordem_servico.valor | guarani }}
              </span>
              <span v-else>
                {{ ordem_servico.moeda_sigla }} {{ ordem_servico.valor | currency }}
              </span>
            </span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.desconto") }}:
              </span>
              <span v-if="ordem_servico.moeda_sigla === 'G$'">
                {{ ordem_servico.moeda_sigla }} {{ ordem_servico.desconto | guarani }}
              </span>
              <span v-else>
                {{ ordem_servico.moeda_sigla }} {{ ordem_servico.desconto | currency }}
              </span>
            </span>
          </div>
        </v-card>

        <BaixaOs
          v-if="ordem_servico.condicao_pagamento === 1"
          :ordem_servico="ordem_servico"
          :formas-pagamentos.sync="formas_pagamentos"
          :valor-restante.sync="totalRestante"
        ></BaixaOs>
        <!-- QUANDO FOR PARCELADO -->
        <v-data-table
          v-if="ordem_servico.condicao_pagamento == 2"
          :headers="headers"
          :items="ordem_servico_parcelas"
          :items-per-page="-1"
          :sort-desc="true"
          class=""
          hide-default-footer
        >
          <template v-slot:item.numero_parcela="{ item }">
            <span v-if="item.entrega_inicial"> Entrega inicial </span>
            <span v-else>
              {{ item.numero_parcela }}
            </span>
          </template>

          <template v-slot:item.total_parcelas="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.total_parcelas }}
            </span>
          </template>

          <template v-slot:item.vencimento="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.vencimento | dateFormat("dd/MM/yyyy") }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="ordem_servico.moeda_sigla === 'G$'">
              {{ ordem_servico.moeda_sigla }} {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ ordem_servico.moeda_sigla }} {{ item.valor | currency }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="mr-4 pa-4">
    
        <v-spacer></v-spacer>
        <PdfDialogComp
          v-if="pdfDialog"
          :pdfDialog.sync="pdfDialog"
          :pdfData="pdfData"
          @close="$emit('update:dialogBaixaOs', false)"
          title="PDF"
        />

        <v-card
          flat
          class="font-weight-bold d-flex pa-0 py-1 align-center pr-3"
        >
          {{ $tc("global.gerarFatura") }}
          <v-switch
            class="ma-0 ml-3 pa-0"
            hide-details
            :false-value="false"
            :true-value="true"
            :disabled="forceGerarFatura ? true : false"
            v-model="gerarFatura"
          ></v-switch>
        </v-card>

        <v-btn
          @click.stop="faturarOs"
          :disabled="faturarDisabled"
          small
          :loading="loading"
          class="white--text"
          color="button_1"
        >
          <v-icon left>mdi-cash-fast</v-icon>
          {{ $tc("global.faturar") }}
        </v-btn>
      </v-card-actions>
      <DialogPrinterOsManual
        v-if="dialogPrinterOsManual"
        :dialogPrinterOsManual.sync="dialogPrinterOsManual"
        :fatura_id.sync="fatura_id"
        :ordem_servico_id.sync="ordem_servico.id"
        :os_condicao_pagamento.sync="ordem_servico.condicao_pagamento"
        @fechar-dialog="fecharDialog"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { faturaOs, fechaBaixaOs } from "@/api/ordem-servico/ordem_servicos.js";
import { index } from "@/api/empresas/empresa_variaveis.js";
import { dataImpressaoFatura, fetchFatura } from "@/api/faturas/faturas.js";
import { mapState } from "vuex";
const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );
const importFaturaAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples_auto_impressor.js"
  );

export default {
  name: "DialogBaixaOs",

  props: {
    dialogBaixaOs: {
      type: Boolean,
      default: false,
    },
    ordem_servico: {
      type: Object,
    },
    ordem_servico_parcelas: {
      type: Array,
    },
  },

  components: {
    BaixaOs: () => import("./BaixaOs.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogPrinterOsManual: () => import("./DialogPrinterOsManual.vue"),
  },

  data() {
    return {
      loading: false,
      gerarFatura: false,
      formas_pagamentos: [],
      dialogPrinterOsManual: false,
      selectedFormaPagamento: null,
      totalRestante: null,
      fatura_id: null,
      pdfDialog: false,
      pdfData: null,
      variaveis: [],
      variavel_obrigatorio_fatura: {},
      contrato: null,
    };
  },

  computed: {
    caixa_id() {
      return this.$route.params.caixa_id;
    },

    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),

    headers() {
      return [
        {
          text: this.$tc("global.numero") + " " + this.$tc("global.parcela"),
          value: "numero_parcela",
        },

        {
          text: this.$tc("global.total") + " " + this.$tc("global.parcela", 2),
          value: "total_parcelas",
        },
        {
          text: this.$tc("global.vencimento"),
          value: "vencimento",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.parcela"),
          value: "valor",
        },
      ];
    },


    faturarDisabled() {
      let result = false;

      if (this.totalRestante >= 0.01) {
        result = true;
      }
      return result;
    },

    forceGerarFatura() {
      let result = false;

      this.formas_pagamentos.find((forma_pagamento) => {
        if (
          forma_pagamento.forma_de_pagamento_id === 6 ||
          forma_pagamento.forma_de_pagamento_id === 7
        ) {
          result = true;
        }
      });

      if (
        this.ordem_servico.condicao_pagamento === 2 ||
        this.variavel_obrigatorio_fatura.valor === "1"
      ) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    forceGerarFatura() {
      if (this.forceGerarFatura) {
        this.gerarFatura = true;
      }
    },
  },

  methods: {
    fecharDialog() {
      this.$emit("update:dialogBaixaOs", false);
      this.$emit("fetch-ordens-servicos");
      this.dialogPrinterOsManual = false;
    },

    fecharBaixaOs() {
      this.$emit("update:dialogBaixaOs", false);
      fechaBaixaOs(this.ordem_servico.id)
        .then(() => {
          this.$emit("fetch-ordens-servicos");
        })
        .catch(() => {});
    },

    faturarOs() {
      this.loading = true;
      let ordem_servico = {};

      ordem_servico.ordem_servico_id = this.ordem_servico.id;
      ordem_servico.caixa_af_id = this.caixa_af.id;
      ordem_servico.gerar_fatura = this.gerarFatura;
      ordem_servico.formas_pagamentos = this.formas_pagamentos;
   
      return faturaOs(ordem_servico)
        .then((response) => {
          if (response.status === 200) {
            if (this.gerarFatura) {             
              (this.fatura_id = response.data.data.fatura_id),          
                (this.dialogPrinterOsManual = true);
            } else {
              this.dialogPrinterOsManual = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async isAutoImpressor(fatura_id) {
      const fatura = await fetchFatura(fatura_id);
      return fatura.timbrado_auto_impressor;
    },

    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      try {
        let autoImpressor = await this.isAutoImpressor(fatura_id);

        if (autoImpressor) {
          importFaturaAutoImpressor()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, true, false, false, "fatura").then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                  dataImpressaoFatura(fatura_id)
                    .then(() => {})
                    .catch(() => {});
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          importFatura()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, print, download, unica, tipo).then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                  dataImpressaoFatura(fatura_id)
                    .then(() => {})
                    .catch(() => {});
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },

  async mounted() {
    this.variaveis = await index(`?empresa_id=${this.ordem_servico.empresa_id}`);
    this.variavel_obrigatorio_fatura = this.variaveis.find(
      (variavel) => variavel.campo === "OBRIGATORIO_EMISSAO_FATURA"
    );
    if (
      this.ordem_servico.condicao_pagamento === 2 ||
      this.variavel_obrigatorio_fatura.valor === "1"
    ) {
      this.gerarFatura = true;
    }
  },
};
</script>

<style></style>
